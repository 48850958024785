import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: any, baseUri: string = 'https://www.youtube.com/embed/'): any {
    const url = `${baseUri}${value}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
