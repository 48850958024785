declare const _satellite: any;
declare const dtmLayer: any;
import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appAdobedtm]'
})
export class AdobedtmDirective {
  @Input('appGa') appGa: string = '';
  @Input('appGaCategory') appGaCategory: string = '';
  @Input('appGaAction') appGaAction: string = '';
  @Input('appGaLabel') appGaLabel: string = '';
  @Input('appGaPush') appGaPush: string = 'false';

  @HostListener('click', ['$event']) onClick($event) {
    if ( this.appGaPush === 'true' ) {
      dtmLayer['eventCategory'] = this.appGaCategory;
      dtmLayer['eventAction'] = this.appGaAction;
      dtmLayer['eventLabel'] = this.appGaLabel;
      _satellite.track('promo');
    }
    return true;
  }

  constructor() { }

}
