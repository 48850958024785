import { Directive, OnInit, ElementRef } from '@angular/core';
declare const FontDetect: any;
declare const jQuery: any;

@Directive({
  selector: '[appPasswordMask]'
})
export class PasswordMaskDirective implements OnInit {

  constructor(private el: ElementRef) { }

  ngOnInit() {
    if ( this.el && this.el.nativeElement && this.detectIE() !== false ) {
      const element = this.el.nativeElement;
      jQuery(element).on('focus', function() {
        if ( !FontDetect.isFontLoaded('password') ) {
          element.setAttribute( 'type', 'password');
        } else {
          element.setAttribute( 'type', 'text');
        }
      });
    }
  }

  detectIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }

}
