declare const dataLayer: any;
import { Injectable } from '@angular/core';

@Injectable()
export class AnalyticsService {

  constructor() { }

  public pushEvent({category, action, label}) {
    try {
        dataLayer.push({
            event: 'ga_event',
            category,
            action,
            label
        });
    } catch (e) {
        //Dont rethrow
    }
  }

}
