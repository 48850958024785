import {Directive, ElementRef, Input, OnInit} from '@angular/core';
declare const jQuery: any;

@Directive({
  selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective implements OnInit {
  @Input('comma') comma = false;
  @Input('dot') dot = false;

  constructor(public element: ElementRef) { }

  ngOnInit() {
    const _self = this;
    jQuery(this.element.nativeElement).on('keydown',  function(event) {
      if ( event.shiftKey ) {
        event.preventDefault();
        return false;
      } else if (event.which === 64 || event.which === 16) {
        // numbers
        return false;
      } if ([8, 9, 13, 27, 37, 38, 39, 40, 46].indexOf(event.which) > -1) {
        // backspace, enter, escape, arrows
        return true;
      } else if (event.which >= 48 && event.which <= 57) {
        // numbers
        return true;
      } else if (event.which >= 96 && event.which <= 105) {
        // numpad number
        return true;
      } else if ([188].indexOf(event.which) > -1 && _self.comma === true ) {
        // dot and numpad dot
        return true;
      } else if ([190, 110].indexOf(event.which) > -1 && _self.dot === true ) {
        // dot and numpad dot
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    });
  }

}
