import { Directive, ElementRef, OnInit, Input } from '@angular/core';
declare const jQuery: any;

@Directive({
  selector: '[appClassPasswordmask]'
})
export class ClassPasswordmaskDirective implements OnInit {
  @Input('appClassPasswordmask') appClassPasswordmask: any = 'Clave Internet';

  constructor(public element: ElementRef) { }

  ngOnInit() {
    const ua = (<any>window).navigator.userAgent;
    const _self = this;
    if ( this.element &&  this.element.nativeElement && ua.indexOf('Edge/') > -1 ) {
      this.element.nativeElement.onfocus = function(event) {
        _self.element.nativeElement.type = 'password';
        return true;
      };
    }
  }

}
