import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import JSEncrypt from 'jsencrypt';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class EncryptionService {
  public encryptMessage(name: string, pubKey: string = environment.login.pubKey): string {
    const crypt = new JSEncrypt();
    crypt.setKey(pubKey);
    return crypt.encrypt(name);
  }

  public getRandomPassword() {
    return CryptoJS.lib.WordArray.random(10).toString();
  }

  public encryptSHA512(message: string) {
    const retornoMsg = CryptoJS.SHA512(message).toString();
    return retornoMsg;
  }
}
