import {Component, Input, OnInit} from '@angular/core';
import { ContenfulService } from '../../services/contenful.service';
import {fadeInEffect} from '../../../animations';
import { CookiesStorageService } from 'ngx-store';
import {environment} from '../../../environments/environment';
declare const dtmLayer: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [fadeInEffect]
})
export class HomeComponent implements OnInit {
  @Input('loading') loading: boolean = false;
  // vars content
  homeHeader;
  homeHeaderContentType = 'homeHeader';
  homePromo = false;
  homeBanner;
  homeBannerBottom = false;
  homeBannerSimple = false;
  componentTitle = false;
  // vars status contentful
  _statusHomeHeader = false;
  _statusHomePromo = false;
  _statusHomeBanner = false;
  _statusHomeBannerSimpple = false;
  _statusHomeBannerBottom = false;
  _statusComponentTitle = false;


  header = {
    "fields": {
        "image": {
            "fields": {
                "title": "Banner WEB 1812x450 TODO",
                "description": "",
                "file": {
                    "url": "//images.ctfassets.net/s6vonmy5x76n/4RI4pGdm7g9T4ScfEMTO9M/361713187fe1a60a6775244c89fa27db/Banner-WEB-1310x450-TODO.jpg",
                    "details": {
                        "size": 227323,
                        "image": {
                            "width": 1310,
                            "height": 450
                        }
                    },
                    "fileName": "Banner-WEB-1310x450-TODO.jpg",
                    "contentType": "image/jpeg"
                }
            }
        },
        "imageMobile": {
            "fields": {
                "title": "Banner Home mobile",
                "description": "",
                "file": {
                    "url": "//images.ctfassets.net/s6vonmy5x76n/7lxmS3tyCpqTfozSTnhavI/b85b38afd94b6f2e80ada16da8c2c939/MicrosoftTeams-image__9_.png",
                    "details": {
                        "size": 198617,
                        "image": {
                            "width": 767,
                            "height": 431
                        }
                    },
                    "fileName": "MicrosoftTeams-image (9).png",
                    "contentType": "image/png"
                }
            }
        }
    }
  }

  banners =[
    {
        "metadata": {
            "tags": []
        },
        "sys": {
            "space": {
                "sys": {
                    "type": "Link",
                    "linkType": "Space",
                    "id": "s6vonmy5x76n"
                }
            },
            "id": "2k4XEUzPoqP3ceEUtMS3o2",
            "type": "Entry",
            "createdAt": "2021-11-22T11:04:56.190Z",
            "updatedAt": "2021-11-26T19:28:33.588Z",
            "environment": {
                "sys": {
                    "id": "master",
                    "type": "Link",
                    "linkType": "Environment"
                }
            },
            "revision": 3,
            "contentType": {
                "sys": {
                    "type": "Link",
                    "linkType": "ContentType",
                    "id": "homeBanner"
                }
            },
            "locale": "es"
        },
        "fields": {
            "title": "Preguntas Frecuentes",
            "infoText": "Al igual que siempre, CMR Falabella continúa su compromiso con cada uno de sus clientes por lo cual te recomendamos leer estas preguntas frecuentes para despejar cualquier duda",
            "actionTitle": "Preguntas Frecuentes",
            "actionUrl": "https://www.cmrfalabella.com.ar/#/faqs",
            "targetActionUrl": "_blank",
            "order": 1,
            "image": {
                "metadata": {
                    "tags": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "s6vonmy5x76n"
                        }
                    },
                    "id": "NE29uMxZFts8clcQz7kZG",
                    "type": "Asset",
                    "createdAt": "2020-08-03T13:43:37.799Z",
                    "updatedAt": "2020-08-03T13:43:37.799Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "revision": 1,
                    "locale": "es"
                },
                "fields": {
                    "title": "BANNERS 33 sorteo",
                    "file": {
                        "url": "//images.ctfassets.net/s6vonmy5x76n/NE29uMxZFts8clcQz7kZG/c9b4d68a11b26df7542de8d5bda6b97f/BANNERS_33_sorteo.jpg",
                        "details": {
                            "size": 54386,
                            "image": {
                                "width": 1375,
                                "height": 350
                            }
                        },
                        "fileName": "BANNERS_33_sorteo.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "contentAlignment": "right"
        }
    }
]
  



  checkPreloadContent = (): boolean => {
    return (
      true
    );
  }

  constructor(
    private _contentService: ContenfulService,
    private cookiesStorageService: CookiesStorageService
  ) { }

  ngOnInit() {
    // dtmLayer
    dtmLayer.pagina = 'home-publica';

    // homeHeader
    if ( this.cookiesStorageService.get(environment.cookieName) === true ) {
      this.homeHeaderContentType = 'inicioBienvenidaClientes';
    }
    this.homeHeader = this.header;

    this.homeBanner = this.banners;
  }

}
