import { Injectable, Inject } from '@angular/core';
import {environment} from '../../environments/environment';
import {ContenfulService} from './contenful.service';
import { DOCUMENT } from "@angular/common";

@Injectable()
export class FeatureToggleService {
    private toggles = null;
    private cookieToggles = [
        {name: 'test', value: 'dGVzdA'},
        {name: 'esconder-caja-login', value: 'ZXNjb2'},
        {name: 'esconder-chat-lia', value: '5kZXIt'},
        {name: 'lia-whatsapp', value: 'bGlhLXdo'}
    ];

    constructor(
        private contentService: ContenfulService,
        @Inject(DOCUMENT) private _document
    ) {}

    isActive = async (feature: string): Promise<boolean> => {
        if(this.toggles === null) {
            this.toggles = await this.contentService.getConfigs();
            this.toggles = this.fullFillToggles(this.toggles);
        }
        return this.toggles[feature] || false;
    }

    isProduction = () => environment.production

    private fullFillToggles(toggles) {
        if(!this.isProduction()) {
           const queryParams = this._document.location.search.slice(1)
            .split(/&/)
            .filter(param => param != "")
            .map(param => ({[param.split(/=/)[0]]: param.split(/=/)[1]}))
            .reduce((accum, param) => {
                Object.keys(param).forEach(key => accum[key] = param[key]);
                return accum
            }, {});
           if(queryParams.features !== undefined) {
            const queryToggles = queryParams.features.split(/,/).map(feature => ({[feature]: true})).reduce((accum, feature) => {
                Object.keys(feature).forEach(key => accum[key] = feature[key]);
                return accum;
            }, {})
            return {...toggles, ...queryToggles};
           }
        }
        else {
            const cookieToggles = this.cookieToggles
                .map(feature => this._document.cookie
                                    .split(/;/)
                                    .filter(cookie => cookie !== "")
                                    .map((cookie) => ({name: cookie.split(/=/)[0].trim(), value: cookie.split(/=/)[1].trim()}))
                                    .find(cookie => cookie.name === feature.name && cookie.value === feature.value))
                .filter(result => result !== undefined)
                .reduce((accum, toggle) => {
                    Object.keys(toggle).filter(key => key === "name").forEach((key) => accum[toggle[key]] = true);
                    return accum;
                }, {});
            return {...toggles, ...cookieToggles};
        }
        return toggles;
    }
}
