import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ContenfulService } from './contenful.service';
import { Router, NavigationStart } from '@angular/router';
import { pipe } from '../helpers/functional';

@Injectable()
export class MetadataService {

  constructor(
    private title: Title,
    private meta: Meta,
    private contentfulService: ContenfulService,
    private router: Router
    ) { }

  getCurrentMetadata = url => metadata => ({
    title: metadata[url] ? metadata[url]['title'] : 'CMR Falabella',
    metaTags: metadata[url] ? metadata[url]['metaTags'] : {}
  })

  updateCurrentMetadata = ({title, metaTags = {}}) => {
    this.title.setTitle(title);
    this.meta.updateTag({name: 'title', content: title});
    Object.keys(metaTags).forEach(key => this.meta.updateTag({name: key, content: metaTags[key]}));
  }
  updateMetadata = url => pipe(
    this.getCurrentMetadata(url),
    this.updateCurrentMetadata
  )
  parseMetadata = metadata => metadata['items'].reduce((acc, {fields: {url, title, metaTags}}) => ({...acc, [url]: {title, metaTags}}), {});
  getMetadataEntries = this.contentfulService.getCachedEntries(this.parseMetadata);

  public suscribeRoutingMetadata = () => {
    this.router.events.subscribe(evt => {
      if ( evt instanceof NavigationStart ) {
          this.getMetadataEntries({content_type: 'metadata'})
          .then(this.updateMetadata(evt.url))
          .catch(() => this.title.setTitle('CMR Falabella'));
      }
    });
  }
}
