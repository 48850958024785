export const environment = {
  omni2login: 'https://banking.cmrfalabella.com.ar/login',
  baseUrlLogin: 'https://banking.cmrfalabella.com.ar/web-clientes/client',
  production: true,
  cookieName: 'isClient',
  contentful: {
    space: 's6vonmy5x76n',
    accessToken: 'aed9d0e04eb245eeabcc89171e42749d752032673d4b897071ba6090157d7cfc',
    host: 'cdn.contentful.com'
  },
  login: {
    serviceUrl: 'https://banking.cmrfalabella.com.ar/Techbank/sso',
    pubKey: '-----BEGIN PUBLIC KEY-----' +
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAs9AMtsjTfTG4NRHOM4Yc' +
    'OXUA3boVAuLr+dgPg95kLPS+bMYUvWnuOb4xHaokCqgjexrSo21P3Jl6TreGi6Gj' +
    'cHAzLA6d1iWndQkbAU5hISBQ82ZctMnqDC5cQamTQ6OmjmvVsxyLwAkGiYB+d44P' +
    '20V725tQApuBSKxEkyHo3fZItcQvs9BD1su6EhOH6ZUMys1cekfqJXtzWkdmeME+' +
    'Gpc7CKSEuAWn0ihoQGmgcxsANYcYpPcC8mT0d2g9cgxGZY0OxiqoqMuQAFgnuoQ+' +
    'zokfaO1EESztQpscggxFAsA7RW01EG6qfVmQw+9IotwbfIHFgTscX98Pe7ihESsP' +
    'FQIDAQAB' +
    '-----END PUBLIC KEY-----'
  },
  showInputLia: false,
  chatLia: 'https://lia.fif.tech/falachat/addon/built/main.js',
  labelChatLia: 'cmr_ar|prd|public',
  google: {
    apiKey: 'AIzaSyDk5e1R4GoNAVAwjUDm25MqqukmgSI4A7c'
  },
  omnichannel: {
    ssoURL: 'https://banking.cmrfalabella.com.ar/Techbank/sso',
    recoverPassURL: 'https://banking.cmrfalabella.com.ar/Techbank/sso?publicSite=autoadhesion'
  },
  cmr: {
    simuladorAdelantoURL: '/SimuladorAdelanto/',
    simuladorPrestamoURL: 'https://www.cmrfalabella.com/b2carpr/CMRCORP/logica/jsp/CMRSimuladorPrestamoResp.html',
    formSolicitudTarjetaURL: '/SolicitudTarjeta/',
    formContactoURL: '/ConsultasYReclamos/',
    pubkey: 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDlOJu6TyygqxfWT7eLtGDwajtN'
    + 'FOb9I5XRb6khyfD1Yt3YiCgQWMNW649887VGJiGr/L5i2osbl8C9+WJTeucF+S76'
    + 'xFxdU6jE0NQ+Z+zEdhUTooNRaY5nZiu5PgDB0ED/ZKBUSLKL7eibMxZtMlUDHjm4'
    + 'gwQco1KRMDSmXSMkDwIDAQAB'
  },
  adobedtm: {
    assetUri: 'https://assets.adobedtm.com/6fa5b5cd2babc0bd10785a36afb0992edfac60c2/satelliteLib-06757b64d83ae237b477b155e10f544557007d49.js'
  },
  bankingServer: {
    serverUrl: 'https://banking.cmrfalabella.com.ar/bankingserver-api-falabella-web',
    oauth2Endpoint: '/oauth/token',
    client: 'Y2xpZW50LXdlYjpmYWxhYmVsbGExMjM0'
  }
};
