import {Component, OnInit, Renderer2, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {fadeInEffect} from '../../../animations';
import { CookiesStorageService, SessionStorage } from 'ngx-store';
import {isUndefined} from 'util';
import {ContenfulService} from '../../services/contenful.service';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { LoginService } from '../../services/login.service';
declare const JSEncrypt: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [fadeInEffect]
})
export class HeaderComponent implements OnInit {
  @Input('overrideRouter') overrideRouter: any = false;
  @Input('tarjetasContent') tarjetasContent: any = false;
  @SessionStorage('statusAlertAviso') statusAlertAviso: any = 'false';
  alertAviso: any = false;
  currentUri = '';
  sidebarOpen = false;
  currentSubmenu = '';
  currentTab = '';
  currentCollapsed = '';
  showAditionalContentTarjetas = false;
  showLogin = false;
  environment = environment;
  configuration: any = '';
  esconderCajaLogin = true;
  rutFull = '';
  rutFull_enc = '';
  identificationType = 'DNI';
  pinseg = '';
  pinseg_enc = '';
  menuPrincipal = [];
  loading = false;

  getCurrentFullUrl = ( extraUri: string = '' ) => {
    const loc = window.location;
    return `${loc['protocol']}//${loc['host']}${loc['pathname']}${extraUri}`;
  }


  checkEdge() {
    const ua = (<any>window).navigator.userAgent;
    return ( ua.indexOf('Edge/') > -1 );
  }

  clearCurrentSubmenu = () => {
    this.currentSubmenu = '';
  }

  setSubMenu = (menu) => {
    this.currentSubmenu = menu;
  }

  updateAditionalContentTarjetas = (state:boolean) => {
    this.showAditionalContentTarjetas = state;
  }

  getSplitItems = (items: Array<any>, pos = 'left') => {
    if ( items && items.length > 0 ) {
      return items.filter( itm => itm.position === pos );
    } else {
      return [];
    }
  }

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private cookiesStorageService: CookiesStorageService,
    private _apiService: ContenfulService,
    private featureToggle: FeatureToggleService,
    private loginService: LoginService
  ) { }

  setLoginBoxToggle = (isActive: boolean) => this.esconderCajaLogin = isActive;

  async ngOnInit() {
  }

}
