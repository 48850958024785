import { Directive, ElementRef, Renderer2, HostListener, OnInit, Input } from '@angular/core';
import {DevicesService} from '../services/devices.service';

const MODAL_STYLE = {
  zIndex: '1201',
  backgroundColor: 'rgba(0,0,0,0.5)',
  position: 'fixed',
  left: '0',
  top: '0',
  bottom: '0',
  right: '0',
  height: '100%',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}
const BUTTON_STYLE = {
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  height: '1.75rem',
  width: '1.75rem',
  borderRadius: '1rem',
  color: 'white',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '5px 5px 0px 0px',
  textDecoration: 'none',
  borderWidth: '0',
  position: 'absolute',
  top: '14px',
  right: '14px',
  fontSize: '125%',
  lineHeight: '1.75rem',
  zIndex: '1',
  pointerEvents: 'auto',
  cursor: 'pointer'
}
const HIDDEN_MODAL_STYLE = {
  opacity: '0',
  visibility: 'hidden',
  transition: 'opacity 0.3s, visibility 0.3s'
}

@Directive({
  selector: '[appModal]'
})

export class ModalDirective implements OnInit {
  isOpened: boolean = true;
  @Input('addedCloseButton') addedCloseButton: boolean = false;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private deviceService: DevicesService
  ) {}

  ngOnInit() {
    this.setStyles(this.el.nativeElement, MODAL_STYLE);
    this.listenClickOutOfBounds();
    if (this.addedCloseButton) this.addCloseButton();
  }

  setStyles = (element, style) => {
    for (const property in style)
      this.renderer.setStyle(element, property, style[property]);
  }

  listenClickOutOfBounds = () => {
    this.renderer.setAttribute(this.el.nativeElement, 'id', 'modal-directive');
    this.renderer.listen(this.el.nativeElement, 'click', (event) => {
      if (event.target.id === 'modal-directive') {
        this.closeModal();
      }
    });
  }

  addCloseButton = () => {
    const content = this.el.nativeElement.children.item(0);
    const closeButton = this.renderer.createElement('button');

    closeButton.innerHTML = '&times;';
    this.renderer.listen(closeButton, 'click', this.closeModal);

    this.setStyles(closeButton,
      (this.deviceService.isSmartPhoneDevice()) ?
        {...BUTTON_STYLE, right: '8px', top: '8px'} : BUTTON_STYLE
    );

    this.renderer.setStyle(content, 'position', 'relative');
    this.renderer.insertBefore(content, closeButton, content.children.item(0));
  }

  closeModal = () => {
    this.setStyles(this.el.nativeElement, HIDDEN_MODAL_STYLE)
    this.isOpened = false;
  }

  @HostListener('document:keyup.esc', ['$event'])
  onPressEsc = () => this.closeModal();

  @HostListener('wheel', ['$event'])
  preventScroll = (event: Event)  => event.preventDefault();

  @HostListener('document:keydown.space', ['$event'])
  onPreventScroolWithSpace = (event: Event)  => {
    if (this.isOpened) event.preventDefault();
  }
}
