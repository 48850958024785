import { Injectable, EventEmitter, Inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { sha512 } from 'js-sha512';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { EncryptionService } from './encryptation.service';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

export interface RequestBody {
  document: string;
  username: string;
  password: string;
}

export interface TokenResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  tokenStore: string;
  token_type: string;
  inactivity_expires_in: number;
  refresh_expires_in: number;
}

@Injectable()
export class LoginService {
  loading: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private encryptService: EncryptionService,
    private http: HttpClient,
    @Inject(DOCUMENT) private document,
  ) {
    this.clearSessionStorage();
  }

  /**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the paramiters to add to the url
 * @param {string} [method=post] the method to use on the form
 */


  makeHttpHeaders(headers = {}) {
    return {
      headers: new HttpHeaders(headers)
    };
  }

  makePostRequest<T>(path, body, headers = {}): Observable<T> {
    const httpHeaders = this.makeHttpHeaders(headers);
    return this.http.post<T>(path, body, httpHeaders);
  }

  doLoginRequest(userData: RequestBody) {
    const client = environment.bankingServer.client;
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Authorization': `Basic ${client}`,
      'channel': 'IN'
    };
    const url = environment.bankingServer.serverUrl + environment.bankingServer.oauth2Endpoint;
    const username = encodeURIComponent(this.encryptService.encryptMessage(userData.username));
    const password = encodeURIComponent(userData.password);

    const document = `${username}_${userData.document}`;
    const body = `grant_type=password&username=${document}&password=${password}`;
    return this.makePostRequest<TokenResponse>(url, body, headers);
  }

  showError(error: string) {
    const parsedUrl = new URL(window.location.href);
    const errUrl = `${parsedUrl.origin}/home`;
    window.location.assign(`${errUrl}?errorMessage=${error}`);
  }

  saveSessionData(res: TokenResponse) {
    const dateToken = Math.round(new Date().getTime() / 1000);
    const refresExpiresIn = Number(res.refresh_expires_in) - 25;
    const inactivityExpiresIn = Number(res.inactivity_expires_in) - 25;
    sessionStorage.setItem('access_token', res.access_token);
    sessionStorage.setItem('expires_in', res.expires_in + '');
    sessionStorage.setItem('refresh_token', res.refresh_token);
    sessionStorage.setItem('token_type', res.token_type);
    sessionStorage.setItem('date_token', dateToken.toString());
    sessionStorage.setItem('date_last_update', dateToken.toString());
    sessionStorage.setItem('date_init_login', dateToken.toString());
    sessionStorage.setItem('refresh_expires_in', refresExpiresIn.toString());
    sessionStorage.setItem('inactivity_expires_in', inactivityExpiresIn.toString());
    sessionStorage.setItem('scope', res.scope);
    sessionStorage.setItem('token_store', res.tokenStore);
  }

  clearSessionStorage() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('expires_in');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('token_type');
    sessionStorage.removeItem('date_token');
    sessionStorage.removeItem('date_last_update');
    sessionStorage.removeItem('date_init_login');
    sessionStorage.removeItem('refresh_expires_in');
    sessionStorage.removeItem('inactivity_expires_in');
    sessionStorage.removeItem('scope');
    sessionStorage.removeItem('token_store');
  }

  setLoading(loading) {
    this.loading.emit(loading);
  }

  public call(body: RequestBody) {
    this.setLoading(true);
    this.doLoginRequest(body)
      .subscribe((res: TokenResponse) => {
        this.setLoading(false);
        this.saveSessionData(res);
        this.document.location.href = environment.baseUrlLogin;
        }, (res) => {
          this.setLoading(false);
          if (res && res.error && res.error.error && res.error.error === 'invalid_grant') {
           this.showError('USER_OR_PASSWORD_NOT_VALID');
          } else {
            this.showError('UNKNOWN_ERROR');
          }
       });
    }
}
