import {Component, OnInit, Input} from '@angular/core';
import {fadeInEffect} from '../../../animations';

@Component({
  selector: 'app-banner-bg-image',
  templateUrl: './banner-bg-image.component.html',
  styleUrls: ['./banner-bg-image.component.css'],
  animations: [fadeInEffect]
})
export class BannerBgImageComponent implements OnInit {
  @Input('content') content = false;
  @Input('index') index = 0;
  @Input('pushEvent') pushEvent = false;
  @Input('categoryEvent') categoryEvent = '';
  @Input('actionEvent') actionEvent = '';
  @Input('labelEvent') labelEvent = '';
  @Input('target') target = '_self';
  bannerImage: any = false;

  getTextContent = (obj:any) => {
    return obj['infoText'] || obj['text'] || '';
  }

  getUriImage = (item: any) => {
    if ( typeof item === 'undefined' ) {
      return '';
    }
    return (item['fields'] && item['fields']['image'] && item['fields']['image']['fields'] &&
      item['fields']['image']['fields']['file']['url']) ? `https:${item['fields']['image']['fields']['file']['url']}?q=60` : '';
  }

  constructor() { }

  ngOnInit() {
  }

}
