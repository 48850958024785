import { Component, OnInit } from '@angular/core';
import {ContenfulService} from '../../services/contenful.service';
import {fadeInEffect} from '../../../animations';
declare const dtmLayer: any;

@Component({
  selector: 'app-atencion-al-usuario-financiero',
  templateUrl: './atencion-al-usuario-financiero.component.html',
  styleUrls: ['./atencion-al-usuario-financiero.component.css'],
  animations: [fadeInEffect]
})
export class AtencionAlUsuarioFinancieroComponent implements OnInit {
  listaDocumento: any = false;
  _statusListaDocumento = false;

  checkPreloadContent = (): boolean => {
    return (
      this._statusListaDocumento === true
    );
  }


  constructor(private _contentService: ContenfulService) { }

  ngOnInit() {
    dtmLayer.pagina = 'atencion-al-usuario-financiero';

    this.listaDocumento =  [
        {
          "metadata": {
            "tags": []
          },
          "fields": {
            "page": "atencion-al-usuario-financiero",
            "title": "Información al usuario de servicios financieros",
            "content": "__Atención al usuario de servicios financieros__\n\nEn CMR Falabella nos encontramos a tu disposición para cualquier consulta, reclamo y/o sugerencia. \nTodas las presentaciones serán analizadas, resueltas y respondidas dentro del plazo de 10 (diez) días hábiles, excepto cuando para su resolución medien causas ajenas a CMR Falabella. \nEl responsable titular de la atención al usuario de servicios financieros es Juan Pablo Caricatti y la responsable suplente, Bárbara Uslenghi. Para contactarse puede escribir un mail a: cmrfalabella@falabella.com.ar o comunicarse al +54 9 261 468 9698.\n\n__Protección al usuario financiero - BCRA__\n\nEn los casos de falta de respuesta de los sujetos obligados o de disconformidad con las resoluciones por ellos adoptadas podrán ser informados por los usuarios de servicios financieros al Banco Central de la República Argentina, organismo que dispone de un Área de Protección al Usuario de Servicios Financieros que podrá contactar ingresando a www.usuariosfinancieros.gob.ar\n\nAsimismo Usted puede consultar el \"Regimén de Transparencia\" elaborado por el Banco Central de la República Argentina sobre la base de la información proporcionada por los sujetos a fin de comparar los costos, características y requisitos de los productos y servicios financieros ingresando [acá](http://www.bcra.gob.ar/BCRAyvos/Regimen_de_Transparencia.asp).\n\n",
            "order": 1
          }
        }
      ]
      this._statusListaDocumento = true;
  }
}
