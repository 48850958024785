import { Component, OnInit } from "@angular/core";
import { ContenfulService } from "../../services/contenful.service";
declare const dtmLayer: any;

@Component({
  selector: "app-faqs",
  templateUrl: "./faqs.component.html",
  styleUrls: ["./faqs.component.css"],
})
export class FaqsComponent implements OnInit {
  componentTitle;
  faqContent;
  _statusComponentTitle = false;
  _statusFaqContent = false;

  checkPreloadContent = (): boolean => {
    return true;
  };

  constructor(private _contentService: ContenfulService) {}

  ngOnInit() {
    dtmLayer.pagina = "faqs";
    this.componentTitle = {
      fields: {
        titleId: "Preguntas-Frecuentes",
        title: "Te ayudamos respondiendo a tus consultas",
      },
    };

    this.faqContent = [
      {
        fields: {
          title:
            "¿Puede CMR ceder los contrato de tarjeta de crédito y/o los derechos crediticios emergentes de dicho contrato?",
          questions:
            "Si, la facultad se encuentra prevista en el contrato de tarjeta de crédito CMR Falabella/ Falabella CMR Mastercard.",
          order: 1,
        },
      },
      {
        fields: {
          title:
            "¿Cómo confirmo si mi contrato fue cedido a Banco Columbia o se cedió el crédito derivado del mismo a Creditia Fideicomiso Financiero?.",
          questions:
            "El 9 de junio de 2021 CMR Falabella Argentina cedió los contratos de tarjeta de crédito “CMR Falabella” y “CMR Mastercard” a BANCO COLUMBIA S.A., quien a partir de esa fecha es el Emisor de las tarjetas, por lo cual los derechos y obligaciones de los contratos de tarjeta y la gestión de las cuentas de las tarjetas están a cargo y son de exclusiva responsabilidad de Banco Columbia.\n\nPor otra parte los contratos con mora superior a 180 días fueron cedidos a Creditia Fideicomiso Financiero. La cesión incluyo todos los derechos, créditos y acciones emergentes de dichos créditos y sus accesorios y garantías y derecho de ejecución e los mismos.\n\nAnte cualquier duda o consulta, podes comunicarte llamando al teléfono +54 9 11 3326-8005 Lunes a sábados 9 a 18 hs. nos mandas tu consulta a nuestro e mail a cmrfalabella@falabella.com.ar",
          order: 2,
        },
      },
      {
        fields: {
          title:
            "¿Qué sucederá con las obligaciones pendientes que tengo con CMR Falabella?",
          questions:
            "Las obligaciones pendientes, como por ejemplo, el pago de lo que adeudes, continúan vigentes aún después de la fecha de finalización del mismo.\n\nPor consultas relacionadas con tu saldo deudor, podrás comunicarte llamando al siguiente teléfono +54 9 11 3326-8005 Lunes a sábados 9 a 18 hs. nos mandas tu consulta a nuestro e mail a cmrfalabella@falabella.com.ar",
          order: 3,
        },
      },
      {
        fields: {
          title:
            "¿Si necesito comunicarme con CMR para, por ejemplo pedir un libre deuda o la reimpresión de un resumen de cuenta o cualquier otra cuestión relacionada con CMR Falabella Argentina ¿a través de que medio lo hago?",
          questions:
            "Podes comunicarte llamando al teléfono +54 9 11 3326-8005 Lunes a sábados 9 a 18 hs. o nos mandas tu consulta a nuestro e mail a cmrfalabella@falabella.com.ar",
          order: 4,
        },
      },
    ];
  }
}
