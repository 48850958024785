import { Directive, ElementRef, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Router } from '@angular/router';
import { anyPass, allPass, not, isEmpty, isUndefined, isNull } from '../helpers/functional';

@Directive({
  selector: 'a'
})
export class HandleLinkDirective implements OnInit, OnChanges {
  @Input('customTarget') customTarget = '';
  @Input('target') target = '';
  @Input('href') href = '';

  element: ElementRef;
  origin = '';

  constructor(
    private el: ElementRef,
    private router: Router
  ) {
    this.element = el;
  }

  ngOnInit() {
    if ( typeof this.customTarget === 'string' && this.customTarget !== '' ) {
      this.el.nativeElement.setAttribute('target', this.customTarget);
    }
  }

  isAsset = uri => uri.includes('//assets.ctfassets.net')
  isTelephone = uri => uri.includes('tel:')
  hasProtocol = uri => /^((http|https|ftp):\/\/)/.test(uri)
  hasHashTag = uri => uri.includes('#')
  hasCustomRoute = allPass(not(isUndefined), not(isNull))

  checkExternalUrl = anyPass(
    this.isAsset,
    this.isTelephone,
    this.hasProtocol
  )

  isSelf = target => target === '_self';

  haveAddListener = allPass(
    ({target}) => this.isSelf(target),
    ({href}) => not(isEmpty)(href),
    ({href}) => not(this.checkExternalUrl)(href),
    ({href}) => not(this.hasHashTag)(href)
  )

  getCustomRoute = (event) => {
    if(this.hasCustomRoute(event.target.getAttribute('custom-route'))) {
      return event.target.getAttribute('custom-route');
    }
    const deepAnchore = event.composedPath().find(element => element['localName'].toString().toLowerCase() === 'a');

    if(deepAnchore && this.hasCustomRoute(deepAnchore.getAttribute('custom-route'))) {
      return deepAnchore.getAttribute('custom-route');
    }
    return null;
  }
  clickEvent = event => {
    if ( event && event.target ) {
      event.preventDefault();
      const customRoute = this.getCustomRoute(event);

      if(customRoute) {
        this.router.navigateByUrl(customRoute);
      } else {
        return true;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes && changes['href'] ) {

      let target = changes['target'] ? changes['target'].currentValue : '_self';
      target = changes['customTarget'] ? changes['customTarget'].currentValue : target;

      const href = changes['href'].currentValue || '';
      if (this.haveAddListener({href, target})) {
        this.el.nativeElement.setAttribute('custom-route', href);
        this.el.nativeElement.addEventListener('click', this.clickEvent);
      }
      this.el.nativeElement.href = href;
      this.el.nativeElement.target = target;
    }
  }
}
