declare const jarallax: any;
import {Directive, OnInit, ElementRef, AfterViewChecked} from '@angular/core';

@Directive({
  selector: '[appJarallax]'
})
export class JarallaxDirective implements OnInit {

  constructor(public el: ElementRef) {
  }

  ngOnInit() {
    const _self = this;
    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document['documentMode'];
    if ( isIE === false ) {
      jarallax(_self.el.nativeElement, {
        speed: 0.7,
        disableParallax: /iPad|iPhone|iPod|Android/
      });
    }
  }

}
