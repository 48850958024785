import { Injectable } from '@angular/core';

@Injectable()
export class DevicesService {
  private DESKTOP: number = 992;
  private TABLET: number = 769;

  constructor() { }

  isTabletDevice = () => window.innerWidth < this.DESKTOP;
  isSmartPhoneDevice = () => window.innerWidth < this.TABLET;
}
