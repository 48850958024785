import { Pipe, PipeTransform } from '@angular/core';
import {isArray, isUndefined} from 'util';

@Pipe({
  name: 'parseDocumentLink'
})
export class ParseDocumentLinkPipe implements PipeTransform {

  transform(value: any, documentLink: any, target: string = '_self', args?: any): any {
    if ( isUndefined(value) === true || value.toString() === '' ||
      isUndefined(documentLink) === true || isArray(documentLink) === false ) {
      return null;
    }
    return value.toString().replace(/"documentLink:\/\/(.*?)"/g, function(match, documentId) {
      if ( documentLink && documentLink[documentId] ) {
        return `"${documentLink[documentId]}" target="${target}"`;
      } else {
        return `""`;
      }
    });
  }

}
