import { Injectable } from '@angular/core';

@Injectable()
export class HelpersService {

  constructor() { }

  easeInOutQuad(t, b, c, d) {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
  }

  scrollTo(element, to, duration = 500) {
    let start = element.scrollTop;
    let change = to - start;
    let currentTime = 0;
    let increment = 20;
  
    
    const animateScroll = () => {        
      currentTime += increment;
      const val = this.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        window.requestAnimationFrame(animateScroll);
      }
    };
  
    animateScroll();
  }
}
