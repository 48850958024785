import {Component, Input, ElementRef, AfterViewInit, OnChanges, SimpleChanges} from '@angular/core';
import { ContenfulService } from '../../../services/contenful.service';
import { pipe } from '../../../helpers/functional';
import { ModalComponent } from '../modal.component';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['../modal.component.css']
})

export class ErrorModalComponent extends ModalComponent implements AfterViewInit, OnChanges {
  @Input('errorCode') errorCode: any;
  errorMessage: any = false;

  constructor(
    private el: ElementRef,
    private contentService: ContenfulService,
  ) {
    super();
  }

  parseError = pipe(
    (entries) => entries.items[0].fields.errorMessage,
    (errorMessage) => this.errorMessage = errorMessage,
    () => this.showModal = true,
  );

  public fetchContent = (contentFilter) => this.contentService.getEntries(contentFilter)
    .then(this.parseError)

  ngOnChanges(changes: SimpleChanges) {
    this.showModal = false;
    if(changes.errorCode.currentValue)
      this.fetchContent({content_type: 'errorCode', 'fields.code': changes.errorCode.currentValue});
  }

  ngAfterViewInit() {
    this.showModal && this.el.nativeElement.querySelector('button.modal-close').focus();
  }
}
