import { Injectable } from '@angular/core';
import {createClient} from 'contentful';
import {environment} from '../../environments/environment';
import { SessionStorageService } from 'ngx-store';
import { sha512 } from 'js-sha512';

@Injectable()
export class ContenfulService {

  private client = createClient({
    space: environment.contentful.space,
    accessToken: environment.contentful.accessToken,
    host: environment.contentful.host
  });

  getEntries = (query?: object): Promise<any>  => {
    return this.client.getEntries(query);
  }

  getEntry = (id?: string): Promise<any>  => {
    return this.client.getEntry(id);
  }

  getCachedEntries = (callback) => async (payload) => {
    const key = sha512(JSON.stringify(payload));
    let storedData = this.sessionStorageService.get(key) ? JSON.parse(this.sessionStorageService.get(key)) : {};
    if (Object.keys(storedData).length > 0) {
      return storedData;
    }
    try {
      const data = await this.getEntries(payload);
      storedData = callback(data);
      this.sessionStorageService.set(key, JSON.stringify(storedData));
      return storedData;
    }catch (err) {
      return storedData;
    }
  };

  private parseConfigs = (configurations) => {
    const _configs = {}
    if (configurations && configurations['items'] && configurations['items'].length > 0) {
      for (const cfg of configurations['items']) {
        if (cfg && cfg['fields'] && cfg['fields']['opcion'] && typeof cfg['fields']['configuracion'] === 'boolean') {
          _configs[cfg['fields']['opcion']] = cfg['fields']['configuracion'];
        }
      }
    }
    return _configs;
  };

  getConfigs = async () => this.getCachedEntries(this.parseConfigs)({content_type: 'configuraciones'});

  getMenuPrincipal = async() => {
    // getMenuPrincipal
    const _menu = [];
    const orderId = [];
    try {
      const menuPrincipal = await this.getEntries({content_type: 'menuPrincipal' , order: 'fields.order'});
      let pos = 0;
      if ( menuPrincipal && menuPrincipal['items'] && menuPrincipal['items'].length > 0 ) {
        for ( const itm of menuPrincipal['items'] ) {
          if ( itm && itm.fields ) {
            orderId[itm.sys.id] = pos;
            _menu[pos] = {
              id: itm.sys.id,
              title: ( itm.fields.title ? itm.fields.title : '' ),
              url: ( itm.fields.url ? itm.fields.url : '' ),
              target: ( itm.fields.target ? itm.fields.target : '' ),
              items: []
            };
            pos++;
          }
        }
      }
      const subMenuPrincipal = await this.getEntries({content_type: 'subMenuPrincipal' , order: 'fields.order'});
      if ( subMenuPrincipal && subMenuPrincipal['items'] && subMenuPrincipal['items'].length > 0 ) {
        for ( const itm of subMenuPrincipal['items'] ) {
          if ( itm && itm.fields && itm.fields.menuPrincipal && itm.fields.menuPrincipal.fields ) {
            const idMp = itm.fields.menuPrincipal.sys.id;
            if ( _menu[orderId[idMp]] ) {
              const tempItm = {
                id: itm.sys.id,
                title: ( itm.fields.title ? itm.fields.title : '' ),
                url: ( itm.fields.url ? itm.fields.url : '' ),
                position: ( itm.fields.position ? itm.fields.position : 'left' ),
                target: ( itm.fields.target ? itm.fields.target : '' )
              };
              _menu[orderId[idMp]].items.push(tempItm);
            }
          }
        }
      }
      return _menu;
    } catch (err) {
      return _menu;
    }
  }

  constructor(private sessionStorageService: SessionStorageService) { }

}
