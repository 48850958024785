import {UrlSerializer, UrlTree, DefaultUrlSerializer} from '@angular/router';

declare function unescape(s: string): string;

export class CustomUrlSerializer implements UrlSerializer {

  parse(url: any): UrlTree {
    const dus = new DefaultUrlSerializer();
    url = unescape(url);
    return dus.parse(url);
  }

  serialize(tree: UrlTree): any {
    const dus = new DefaultUrlSerializer(),
    path = dus.serialize(tree);
    return path;
  }
}
