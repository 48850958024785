import {HomeComponent} from './pages/home/home.component';
import {Routes, RouterModule} from '@angular/router';
import {FaqsComponent} from './pages/faqs/faqs.component';
import {TerminosCondicionesComponent} from './pages/terminos-condiciones/terminos-condiciones.component';
import { LegalesComponent } from './pages/legales/legales.component';
import { AtencionAlUsuarioFinancieroComponent } from './pages/atencion-al-usuario-financiero/atencion-al-usuario-financiero.component';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'faqs',
    component: FaqsComponent
  },
  {
    path: 'terminos-condiciones',
    component: TerminosCondicionesComponent
  },
  {
    path: 'legales',
    component: LegalesComponent
  },
  {
    path: 'atencion-al-usuario-financiero',
    component: AtencionAlUsuarioFinancieroComponent
  },
  { path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }

];
export const AppRouting = RouterModule.forRoot(appRoutes, {
  useHash: false
});
