import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { environment } from '../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ContenfulService } from './services/contenful.service';
import { SessionStorage } from 'ngx-store';
import { FeatureToggleService } from './services/feature-toggle.service';
import { MetadataService } from './services/metadata.service';
import { pipe } from './helpers/functional';
import { DOCUMENT } from '@angular/common';

declare const dtmLayer: any;
declare const _satellite: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @SessionStorage('statusModalMarketing') statusModalMarketing: any = 'false';
  trustedFormURL: SafeResourceUrl;
  modalContent: any = false;
  errorCode: any = '';

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private _renderer2: Renderer2,
    private sanitizer: DomSanitizer,
    private _contentService: ContenfulService,
    @Inject(DOCUMENT) private _document
  ) {
  }


  ngOnInit() {

    dtmLayer.sitio = 'publico';
    this.route.queryParams.subscribe( (params) => {
      this.errorCode = params['errorMessage'] ? params['errorMessage'] : false;
    });
  }
}
