import { Component, ElementRef, AfterContentInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'img',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent implements AfterViewInit {

  constructor(public el: ElementRef) { }

  ngAfterViewInit() {
    if ( this.el && this.el.nativeElement ) {
      if ( this.el.nativeElement.src.indexOf('//images') > -1 && this.el.nativeElement.src.indexOf('?q=60') === -1 ) {
        this.el.nativeElement.src = `${this.el.nativeElement.src}?q=60`;
      }
    }

  }

}
