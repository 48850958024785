import {Component, OnInit, Input, HostListener, OnChanges, SimpleChanges} from '@angular/core';
import {fadeInEffect} from '../../../animations';

@Component({
  selector: 'app-header-page',
  templateUrl: './header-page.component.html',
  styleUrls: ['./header-page.component.css'],
  animations: [fadeInEffect]
})
export class HeaderPageComponent implements OnInit, OnChanges {
  @Input('content') content = false;
  @Input('pushEvent') pushEvent = false;
  @Input('categoryEvent') categoryEvent = '';
  @Input('actionEvent') actionEvent = '';
  @Input('labelEvent') labelEvent = '';
  bannerImage: any = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getImageUri();
  }

  getImageUri = () => {
    if ( this.checkIsMobile() === true && ( this.content && this.content['fields'] &&
        this.content['fields']['imageMobile'] && this.content['fields']['imageMobile']['fields'] ) ) {
        this.bannerImage = `${this.content['fields']['imageMobile']['fields']['file']['url']}?q=60`;
    } else if ( this.content && this.content['fields'] &&
      this.content['fields']['image'] && this.content['fields']['image']['fields'] ) {
      this.bannerImage = `https:${this.content['fields']['image']['fields']['file']['url']}?q=60`;
    } else {
      return '';
    }

  }

  checkIsMobile = () => {
    return ( window.innerWidth <= 991 );
  }

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getImageUri();
  }

}
