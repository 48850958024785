import { Component, OnInit } from '@angular/core';
import {ContenfulService} from '../../services/contenful.service';
import {fadeInEffect} from '../../../animations';
declare const dtmLayer: any;

@Component({
  selector: 'app-legales',
  templateUrl: './legales.component.html',
  styleUrls: ['./legales.component.css'],
  animations: [fadeInEffect]
})
export class LegalesComponent implements OnInit {
  listaDocumento: any = false;
  _statusListaDocumento = false;
  documentLink: any = '';
  _statusDocumentLink = false;

  checkPreloadContent = (): boolean => {
    return (
      this._statusListaDocumento === true &&
      this._statusDocumentLink === true
    );
  }


  constructor(private _contentService: ContenfulService) { }

  ngOnInit() {
    dtmLayer.pagina = 'legales';

    // listaDocumento
    this._contentService.getEntries({content_type: 'listaDocumento', 'fields.page': 'legales',
      order: 'fields.order'})
      .then(entries => {
        this._statusListaDocumento = true;
        if (entries && entries['items'] && entries['items'].length > 0) {
          this.listaDocumento = entries['items'];
        }
      });

    // documentLink
    this._contentService.getEntries({content_type: 'documentLink'})
      .then(entry => {
        this._statusDocumentLink = true;
        if ( entry && entry['items'] && entry['items'].length > 0){
          this.documentLink = [];
          const items = entry['items'];
          for (const it of items){
            if ( it && it['fields'] && it['fields']['documentId'] &&
              it['fields']['asset'] && it['fields']['asset']['fields'] ) {
              const key = it['fields']['documentId'];
              const value =  it['fields']['asset']['fields']['file']['url'];
              this.documentLink[key] = value;
            }
          }
        }
      });
  }
}
