import { BrowserModule } from '@angular/platform-browser';
import {UrlSerializer} from '@angular/router';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {AppRouting} from './app.routing';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WebStorageModule } from 'ngx-store';
import { environment } from '../environments/environment';
import { APP_BASE_HREF, LocationStrategy, HashLocationStrategy } from '@angular/common';

//Services
import { AnalyticsService } from './services/analytics.service';
import { ContenfulService } from './services/contenful.service';
import { FeatureToggleService } from './services/feature-toggle.service';
import { HelpersService } from './services/helpers.service';
import { DevicesService } from './services/devices.service';
import { CookieService } from 'ngx-cookie-service';
import { MetadataService } from './services/metadata.service';

//Pipes
import { MarkedPipe } from './pipes/marked.pipe';
import { ParseDocumentLinkPipe } from './pipes/parse-document-link.pipe';
import { SafePipe } from './pipes/safe.pipe';

//Directives
import { AdobedtmDirective } from './directives/adobedtm.directive';
import { BindEnterDirective } from './directives/bind-enter.directive';
import { ClassPasswordmaskDirective } from './directives/class-passwordmask.directive';
import { DropdownDirective } from './directives/dropdown.directive';
import { HandleLinkDirective } from './directives/handle-link.directive';
import { JarallaxDirective } from './directives/jarallax.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { PasswordMaskDirective } from './directives/password-mask.directive';
import { ScrolltoDirective } from './directives/scrollto.directive';
import { SwiperDirective } from './directives/swiper.directive';
import { ModalDirective} from './directives/modal.directive';

//Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderPageComponent } from './components/header-page/header-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { BannerBgImageComponent } from './components/banner-bg-image/banner-bg-image.component';
import { BannerDefaultComponent } from './components/banner-default/banner-default.component';
import { ContentBoxComponent } from './components/content-box/content-box.component';
import { FaqComponent } from './components/faq/faq.component';
import { LoaderComponent } from './components/loader/loader.component';
import { BannerSlimComponent } from './components/banner-slim/banner-slim.component';
import { CollapseComponent } from './components/collapse/collapse.component';
import {CustomUrlSerializer} from './serializer/custom.serializer';
import { ImageComponent } from './components/image/image.component';
import { ModalComponent } from './components/modal/modal.component';
import { ErrorModalComponent } from './components/modal/error-modal/error-modal.component';

//Pages
import { HomeComponent } from './pages/home/home.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { TerminosCondicionesComponent } from './pages/terminos-condiciones/terminos-condiciones.component';
import { LegalesComponent } from './pages/legales/legales.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginService } from './services/login.service';
import { EncryptionService } from './services/encryptation.service';
import { AtencionAlUsuarioFinancieroComponent } from './pages/atencion-al-usuario-financiero/atencion-al-usuario-financiero.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    HeaderPageComponent,
    FooterComponent,
    BannerBgImageComponent,
    BannerDefaultComponent,
    JarallaxDirective,
    SwiperDirective,
    ContentBoxComponent,
    MarkedPipe,
    DropdownDirective,
    FaqComponent,
    FaqsComponent,
    LoaderComponent,
    ScrolltoDirective,
    BannerSlimComponent,
    TerminosCondicionesComponent,
    LegalesComponent,
    AdobedtmDirective,
    CollapseComponent,
    ParseDocumentLinkPipe,
    ImageComponent,
    BindEnterDirective,
    OnlyNumbersDirective,
    PasswordMaskDirective,
    ClassPasswordmaskDirective,
    HandleLinkDirective,
    SafePipe,
    ModalDirective,
    ModalComponent,
    ErrorModalComponent,
    AtencionAlUsuarioFinancieroComponent
  ],
  imports: [
    AppRouting,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google.apiKey,
      libraries: ['places', 'geometry']
    }),
    WebStorageModule,
    HttpClientModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    AnalyticsService,
    ContenfulService,
    FeatureToggleService,
    HelpersService,
    DevicesService,
    CookieService,
    LoginService,
    EncryptionService,
    MetadataService,
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
