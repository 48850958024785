import {Directive, ElementRef, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Directive({
  selector: '[appBindEnter]'
})
export class BindEnterDirective implements OnInit {
  @Input('formToSubmit') formToSubmit: any = false;
  @Input('formCheck') formCheck: any = false;
  @Output('submitEvent') submitEvent: EventEmitter<any> = new EventEmitter();

  constructor(public element: ElementRef) { }

  ngOnInit() {
    const _self = this;
    this.element.nativeElement.onkeydown = function(event) {
      if (event.which === 13 !== false && _self.formCheck === true ) {
        // numbers
        _self.submitEvent.emit(true);
        if (_self.formToSubmit !== false) { document.querySelector(_self.formToSubmit).submit(); }
        return false;
      } else {
        return true;
      }
    };
  }

}
