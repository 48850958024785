import {Component, Input, OnInit} from '@angular/core';
import {fadeInEffect} from '../../../animations';

@Component({
  selector: 'app-banner-slim',
  templateUrl: './banner-slim.component.html',
  styleUrls: ['./banner-slim.component.css'],
  animations: [fadeInEffect]
})
export class BannerSlimComponent implements OnInit {
  @Input('content') content = false;

  constructor() { }

  ngOnInit() {
  }

}
