import {trigger, transition, style, animate, state, group, query, stagger, keyframes} from '@angular/animations';

export const fadeInEffect = trigger(
  'fadeInEffect',
    [
      transition(
        ':enter', [
          style({transform: '', opacity: 0}),
          animate('300ms', style({transform: '', 'opacity': 1}))
        ]
      ),
      transition(
        ':leave', [
          style({transform: '', 'opacity': 1}),
          animate('300ms', style({transform: '', 'opacity': 0}))
        ]
      )]
);

export const visibility = trigger(
  'visibility', [
    transition(':enter', [
      style({transform: 'translateY(-100%)', opacity: 0}),
      animate('300ms', style({transform: 'translateY(0)', opacity: 1}))
    ]),
    transition(':leave', [
      style({transform: 'translateY(0)', opacity: 1}),
      animate('300ms', style({transform: 'translateY(-100%)', opacity: 0}))
    ])
  ]
)
