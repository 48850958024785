import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from '../../directives/modal.directive';

@Component({
  selector: 'app-modal',
  template: 'NO TEMPLATE',
})
export class ModalComponent {
  closeModal: any;
  showModal: boolean = false;

  @ViewChild(ModalDirective, {static: false}) set closeModalDirective(directive: ModalDirective) {
    if(this.showModal) this.closeModal = directive.closeModal;
  }
  constructor() {}
}
